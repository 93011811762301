<template>
  <div class="pd10">
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">{{$route.meta.title}}</span>
        <el-button class="right" type="text" @click="$router.back()">返回</el-button>
      </div>
      <el-row>
        <el-select v-if="tempInfo.type===0" v-model="high" placeholder="请选择" class="mgB10 custom-form-input">
          <el-option label="全部" value="">
          </el-option>
          <el-option v-for="(item,index) in tempInfo.high" :key="index" :label="'第'+item+'层'" :value="item">
          </el-option>
        </el-select>
        <el-select v-else v-model="high" placeholder="请选择" class="mgB10 custom-form-input">
          </el-option>
          <el-option v-for="(item,index) in highs" :key="index" :label="'第'+item+'圈'" :value="item">
          </el-option>
        </el-select>
        <el-button type="primary" @click="search" :loading="loading" :disabled="loading" plain class="mgL10 mgB10">搜索
        </el-button>
        <el-button type="info" class="mgL10 mgB10" @click="detailVisible=true">详情</el-button>
      </el-row>
      <el-table :data="tableData" border stripe v-loading="loading" class="custom-table"
        header-row-class-name="custom-header-row" :row-class-name="rowClassName">
        <el-table-column v-for="(item,index) in cols" :key="index" :prop="item.value" :show-overflow-tooltip="true"
          min-width="40" header-align="center" align="center" :label="item.name">
          <template slot-scope="scope">
            <!--        <el-tooltip v-if="scope.row[scope.column.property].title" class="item" effect="dark"
              :content="scope.row[scope.column.property].title" placement="top-start"> -->
            <span v-if="scope.row[scope.column.property].oldValue"
              :style="scope.row|colorFilter(scope.column.property)">{{scope.row[scope.column.property].value}}</span>
            <!-- </el-tooltip> -->


            <span v-else>{{scope.row[scope.column.property].value}}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 数据详情 -->
    <el-dialog class="custom-dialog" title="数据详情" :visible.sync="detailVisible" width="520px" :append-to-body="true">
      <TempDetail :tempInfo="tempInfo" />
    </el-dialog>
  </div>
</template>

<script>
  var minAndHighTemp = {}
  import {
    getMinAndHighTemp,
    group,
    tablePlaneColor,
    validTempTable,
    validateTempNo
  } from "@/common/index.js"
  export default {
    components: {
      TempDetail: () => import('./components/TempDetail')
    },
    filters: {
      colorFilter(row, column) {
        return tablePlaneColor(row[column].oldValue, minAndHighTemp.minTemp, minAndHighTemp.maxTemp);
      }
    },
    data() {
      return {
        high: '',
        tempId: '',
        loading: false,
        tempInfo: {},
        detailVisible: false,
        cols: [],
        tableData: [],
        highs: 1
      }
    },
    created() {
      this.tempId = this.$route.query.id
      this.search()
    },
    methods: {
      //搜索
      search() {
        var params = {
          id: this.tempId
        }
        if (this.high) params["high"] = this.high - 1;
        this.loading = true
        this.$axios.Get(this.$api.byIdTableHighOrCircle, params).then(res => {
          this.tempInfo = res.data
          if (this.tempInfo.type !== 0) {
            if (!this.high) this.high = 1;
            this.highs = res.data.row * 1;
          }
          this.cols = res.data.type === 0 ? this.colWareFormat(res.data) : this.colSiloFormat(res.data)
          this.tableData = res.data.type === 0 ? this.dataWareFormat(res.data) : this.dataSiloFormat(res.data)
          this.loading = false
        }).then(() => {
          this.loading = false
        })
      },
      //获取里筒仓列
      colSiloFormat(tempInfo) {
        let cols = [{
          name: '层数',
          value: 'high'
        }]

        let colNumber = tempInfo.col.split("#"),
          col = 0;
        colNumber = colNumber.filter(item => item)
        for (let j = 0; j < this.high - 1; j++) {
          col += colNumber[j] * 1;
        }
        for (let i = col; i < col + colNumber[this.high - 1] * 1; i++) {
          cols.push({
            name: "第" + (i * 1 + 1) + "根",
            value: 'field' + cols.length
          })
        }
        return cols;
      },
      dataSiloFormat(tempInfo) {
        minAndHighTemp = getMinAndHighTemp(tempInfo.temps);

        let val = group(tempInfo.temps, group(tempInfo.temps, tempInfo.high).length),
          rows = [];
        for (let i in val) {
          let array = val[i],
            row = {
              high: {
                value: "第" + (i * 1 + 1) + "层"
              }
            };

          for (let j in array) {
            row['field' + (j * 1 + 1)] = {
              value: array[j] == 'k' ? "" : validTempTable(validateTempNo(array[j]), tempInfo.minimumTemp, tempInfo
                .highestTemp),
              oldValue: array[j]
            }
          }
          rows.push(row)
        }
        return rows
      },
      //后获取列
      colWareFormat(tempInfo) {
        let cols = [{
          name: '行数',
          value: 'row'
        }, {
          name: '层数',
          value: 'high'
        }]

        if (tempInfo.startPointPosition == 2 || tempInfo.startPointPosition == 3) {
          for (let i = tempInfo.col - 1; i >= 0; i--) {
            cols.push({
              name: '第' + (i * 1 + 1) + '列',
              value: 'field' + i
            })
          }
        } else {
          for (let i = 0; i < tempInfo.col; i++) {
            cols.push({
              name: '第' + (i * 1 + 1) + '列',
              value: 'field' + i
            })
          }
        }
        return cols;
      },
      //平方仓表格数据
      dataWareFormat(tempInfo) {
        minAndHighTemp = getMinAndHighTemp(tempInfo.temps);
        var arrays = group(group(tempInfo.temps, tempInfo.col), tempInfo.row),
          rows = [];

        for (let i in arrays) {
          let array = arrays[i];
          let highDs = this.high == '' ? 0 : this.high;
          for (let j in array) {
            let rowCurrent = (j * 1 + 1);
            if (tempInfo.startPointPosition == 2 || tempInfo.startPointPosition == 3) {
              rowCurrent = (tempInfo.row * 1 - j * 1);
            }

            let data = array[j],
              row = {
                high: {
                  value: "第" + (i * 1 + 1 + highDs * 1) + "层"
                },
                row: {
                  value: '第' + rowCurrent + "行 "
                }
              };
            for (let k in data) {
              // let c = k * 1 + 1;
              // if (tempInfo.startPointPosition == 2 || tempInfo.startPointPosition == 3) {
              //   c = tempInfo.col * 1 - k * 1;
              // }
              // let title = rowCurrent + "行 " + (i * 1 + 1 + highDs * 1) + "层 " + c + "列";
              row['field' + k] = {
                value: validTempTable(validateTempNo(data[k], tempInfo.minimumTemp, tempInfo.highestTemp)),
                // title: title,
                oldValue: data[k]
              }
            }
            rows.push(row)
          }
        }
        return rows
      },
      //航新样式
      rowClassName({
        rowIndex
      }) {
        if (rowIndex !== 0) {
          if (this.tempInfo.type === 0) {
            if (rowIndex % this.tempInfo.row === 0) {
              return "custom-row"
            }
          } else {
            return "custom-row"
          }
        }
        // if (rowIndex !== 0 && rowIndex % this.tempInfo.row === 0) {
        //   return "custom-row"
        // }else{

        // }
      }
    }
  }
</script>

<style scoped lang="less">
  .custom-table {
    width: 100%;
    border: 2px solid #000000;

    /deep/ th.gutter {
      display: table-cell !important
    }

    /deep/ td+td {
      border-left: 2px solid #000000;
    }
  }


  /deep/ .custom-header-row {
    th {
      // border-bottom: 2px solid #000000;
      // border-top: 2px solid #000000;
      // background-color: ;
      border-bottom: 2px solid #000;
      background:#e9e9e9;
    }

    th+th {
      border-left: 2px solid #000000;
    }
  }

  /deep/ .custom-row td {
    border-top: 2px solid #000000;
  }
</style>
